<template>

  <div class="tab-content col-lg-10 col-md-10 ">
    <div class="iq-card-body">
      <div class="row">
        <!-- b-statements__aside -->
        <OtherLeftMenu :menuId="selectedId"></OtherLeftMenu>
        <div class="col-lg-9">

          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{$t('label.qrCode')}}</h4>
            </template>
            <template v-slot:body>
              <div>
                <label class="form-label" for="colFormLabel">{{$t('label.qrCodeDesc')}}
                </label>
                <div class="d-flex justify-content-center">
                  <vue-qrcode tag="img" id="qr" :value="url" :options="{
                        errorCorrectionLevel: 'Q',
                        width: 250
                      }"></vue-qrcode>
                </div>
                <div class="d-grid gap-2 pt-2">
                  <button @click="dlQr" type="button" class="btn btn-primary rounded-pill">
                    {{$t('label.dlQrCode')}}
                  </button>
                </div>
              </div>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OtherLeftMenu from '../User/Components/OtherLeftMenu'
import { mapGetters } from 'vuex'

export default {
  name: 'QrSeting',
  props: ['menuId'],
  components: {
    OtherLeftMenu
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState',
      isPlan: 'User/isPlanState'
    })
  },
  data() {
    return {
      url: '',
      selectedId: 3
    }
  },
  methods: {
    dlQr() {
      const linkSource = `${document.getElementById('qr').src}`
      console.log(linkSource)
      const downloadLink = document.createElement('a')
      downloadLink.href = linkSource
      downloadLink.download = 'qr'
      downloadLink.click()
    },
    reReady() {},
    onReady(canvas) {
      const context = canvas.getContext('2d')
      const image = new Image()
      image.src = this.user.avatar
      image.crossorigin = 'anonymous'
      image.onload = () => {
        const coverage = 0.15
        const width = Math.round(canvas.width * coverage)
        const x = (canvas.width - width) / 2
        this.drawImage(context, image, x, x, width, width)
      }
    },

    drawImage(context, image, x, y, width, height, radius = 4) {
      context.shadowOffsetX = 0
      context.shadowOffsetY = 2
      context.shadowBlur = 4
      context.shadowColor = '#00000040'
      context.lineWidth = 8

      // context.font = '20px Arial'
      // context.textAlign = 'bottom'
      // context.fillText(58585888, width + 20, 200)

      context.beginPath()
      context.moveTo(x + radius, y)
      context.arcTo(x + width, y, x + width, y + height, radius)
      context.arcTo(x + width, y + height, x, y + height, radius)
      context.arcTo(x, y + height, x, y, radius)
      context.arcTo(x, y, x + width, y, radius)
      context.closePath()
      context.strokeStyle = '#fff'
      context.stroke()
      context.clip()
      context.fillStyle = '#fff'
      context.fillRect(x, x, width, height)
      context.drawImage(image, x, x, width, height)
    }
  },
  created() {
    this.url = `${process.env.VUE_APP_FRONT_END_URL}${this.user.account}`
  }
}
</script>
